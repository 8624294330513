<template>
  <div class="box-container">
    <div class="main-container">
      <h5 class="main-title">OVERVIEW</h5>
      <div class="bs-flex">
        <div class="left-item">
          <h5 class="bs-title">질환 별 분포도</h5>
          <DiagnosisCircle/>
        </div>
        <div class="right-item">
          <h5 class="bs-title">Fugl-Meyer(상지) 점수 분포도</h5>
        <FmCircle/>
        </div>
      </div>
      <h5 class="bs-title">서비스 이용 연령대 분포도</h5>
      <AgeCircle/>
      <h5 class="bs-title">서비스 이용 병원 분포도</h5>
      <HospitalCircle/>
    </div>
  </div>
</template>

<script>
import HospitalCircle from "./overview/HospitalCircle";
import AgeCircle from "./overview/AgeCircle";
import DiagnosisCircle from "@/views/master/overview/DiagnosisCircle";
import FmCircle from "@/views/master/overview/FmCircle";

export default {
  name: "Overview",
  components: {
    FmCircle,
    DiagnosisCircle,
    AgeCircle,
    HospitalCircle,
  },
  data() {
    return {
      pieChartData: {
        labels: ["Red", "Green", "Yellow", "Grey", "Dark Grey"],
        datasets: [
          {
            data: [300, 50, 100, 40, 120],
            backgroundColor: [
              "#E35D71",
              "#DF964B",
              "#4DA89E",
              "#376EB9",
              "#4D4D5E"
            ],
            hoverBackgroundColor: [
              "#F17C8D",
              "#F3B26F",
              "#69CABF",
              "#6693CF",
              "#626271"
            ]
          }
        ]
      },
      pieChartOptions: {
        legend: {
          fullWidth: false,
          position: 'bottom'
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: "white",
            align: "center",
            font: {
              size: 14
            },
            formatter: value => {
              const [dataset] = this.pieChartData.datasets;
              const setValue = dataset.data.reduce((a, b) => a + b);

              return `${Math.round((value / setValue) * 100)}%`;
            }
          }
        }
      }
    };
  }
}
</script>

<style scoped>

.box-container {
  width: 1110px;
  margin-left: auto;
  margin-right: auto;
}


.main-container {
  background: white;
  padding: 5px 38px;
}

.main-title {
  font-size: 24px;
  font-weight: 600;
  padding: 40px 0 30px 0;
}

.bs-flex {
  display: flex;
  flex-direction: row;
  max-width: 830px;
  justify-content: space-between;
}

.bs-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #333333;
  margin: 20px 0 15px;
}

.chart-container {
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 15px;
  padding-bottom: 20px;
}

.bs-sub-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #333333;
  padding: 20px 0 12px 20px;
}

/deep/ .chartjs-render-monitor {
  width: 400px !important;
}

.double-container {
  max-width: 830px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-bottom: 20px;
}

.double-chart-container .bs-sub-title {
  padding: 20px 0 12px 62px;
}
</style>
