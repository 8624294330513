<template>
  <div class="double-container">
    <div class="double-chart-container" v-if="isDataDone">
      <p class="bs-sub-title">치료사</p>
      <mdb-pie-chart
          datalabels
          :data="pieChartData"
          :options="pieChartOptions"
          :width="300"
          :height="300"
          v-if="isShowTherapist"
      />
      <div class="null-box" v-else>
        <img src="@/assets/images/IA/icon_null_document.png" alt="null">
        <p>데이터가 없습니다.</p>
      </div>
    </div>
    <div class="double-chart-container" v-if="isDataDone2">
      <p class="bs-sub-title">환자</p>
      <mdb-pie-chart
          datalabels
          :data="pieChartData2"
          :options="pieChartOptions2"
          :width="300"
          :height="300"
          v-if="isShowPatients"
      />
      <div class="null-box" v-else>
        <img src="@/assets/images/IA/icon_null_document.png" alt="null">
        <p>데이터가 없습니다.</p>
      </div>
    </div>
  </div>
</template>

<script>
import {mdbPieChart} from "mdbvue";
import {firebase} from "../../../firebase/firebaseConfig";

export default {
  name: "AgeCircle",
  components: {
    mdbPieChart,
  },

  data() {
    return {
      isDataDone: false,
      isDataDone2: false,
      isShowTherapist: true,
      isShowPatients: true,
      pieChartData: {
        labels: ["전주대", "Green", "Yellow", "Grey", "Dark Grey"],
        datasets: [
          {
            data: [300, 50, 100, 40, 120],
            backgroundColor: [
              "#E35D71",
              "#DF964B",
              "#4DA89E",
              "#376EB9",
              "#4D4D5E"
            ],
            hoverBackgroundColor: [
              "#F17C8D",
              "#F3B26F",
              "#69CABF",
              "#6693CF",
              "#626271"
            ]
          }
        ]
      },
      pieChartData2: {
        labels: ["전주대", "Green", "Yellow", "Grey", "Dark Grey"],
        datasets: [
          {
            data: [300, 50, 100, 40, 120],
            backgroundColor: [
              "#E35D71",
              "#DF964B",
              "#4DA89E",
              "#376EB9",
              "#4D4D5E"
            ],
            hoverBackgroundColor: [
              "#F17C8D",
              "#F3B26F",
              "#69CABF",
              "#6693CF",
              "#626271"
            ]
          }
        ]
      },
      pieChartOptions: {
        legend: {
          fullWidth: false,
          position: 'bottom'
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: "white",
            align: "center",
            font: {
              size: 14
            },
            formatter: value => {
              const [dataset] = this.pieChartData.datasets;
              const setValue = dataset.data.reduce((a, b) => a + b);

              return `${Math.round((value / setValue) * 100)}%`;
            }
          }
        }
      },
      pieChartOptions2: {
        legend: {
          fullWidth: false,
          position: 'bottom'
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: "white",
            align: "center",
            font: {
              size: 14
            },
            formatter: value => {
              const [dataset] = this.pieChartData2.datasets;
              const setValue = dataset.data.reduce((a, b) => a + b);

              return `${Math.round((value / setValue) * 100)}%`;
            }
          }
        }
      },

      therapistList: [],
      hospitalList: [],
      hospitalTot: 0,
    };
  },
  async mounted() {
    await this.therapistData()
    await this.patientsData()
  },
  methods: {
    async therapistData() {
      const self = this;
      self.hospitalList.splice(0)
      const db = firebase.firestore();
      await db.collection('therapists')
          .get()
          .then(async (querySnapshot) => {
            if (querySnapshot.size !== 0) {
              self.hospitalTot = querySnapshot.size
              querySnapshot.forEach((doc) => {
                const _data = doc.data();
                const now = new Date()
                const nowYear = now.getFullYear().toString()

                let birthYear = new Date(_data.birth)
                birthYear = birthYear.getFullYear().toString()

                const ageVal = nowYear - birthYear;

                if (ageVal >= 60) {
                  _data.age = '60대 이상'
                } else if (ageVal >= 50) {
                  _data.age = '50대'
                } else if (ageVal >= 40) {
                  _data.age = '40대'
                } else if (ageVal >= 30) {
                  _data.age = '30대'
                } else if (ageVal >= 20) {
                  _data.age = '20대'
                } else {
                  _data.age = '10대'
                }

                self.hospitalList.push(_data.age)
              });
            } else {
              self.isShowTherapist = false;
            }
          });

      const arr = self.hospitalList;

      const result = {};
      arr.forEach((x) => {
        result[x] = (result[x] || 0) + 1;
      });

      const keyList = Object.keys(result)
      self.pieChartData.labels = keyList

      const valList = Object.values(result)
      self.pieChartData.datasets[0].data = valList

      if (keyList.length > 5)
        self.addRandomColors(self.pieChartData.datasets[0].backgroundColor, keyList.length)

      self.isDataDone = true;
    },

    async patientsData() {
      const self = this;
      self.hospitalList.splice(0)
      const db = firebase.firestore();
      await db.collection('users')
          .get()
          .then(async (querySnapshot) => {
            if (querySnapshot.size !== 0) {
              self.hospitalTot = querySnapshot.size
              querySnapshot.forEach((doc) => {
                const _data = doc.data();
                const now = new Date()
                const nowYear = now.getFullYear().toString()

                let birthYear = new Date(_data.birth)
                birthYear = birthYear.getFullYear().toString()

                const ageVal = nowYear - birthYear;

                if (ageVal >= 60) {
                  _data.age = '60대 이상'
                } else if (ageVal >= 50) {
                  _data.age = '50대'
                } else if (ageVal >= 40) {
                  _data.age = '40대'
                } else if (ageVal >= 30) {
                  _data.age = '30대'
                } else if (ageVal >= 20) {
                  _data.age = '20대'
                } else {
                  _data.age = '10대'
                }

                self.hospitalList.push(_data.age)

              });
            } else {
              self.isShowPatients = false;
            }
          });

      const arr = self.hospitalList;

      const result = {};
      arr.forEach((x) => {
        result[x] = (result[x] || 0) + 1;
      });

      const keyList = Object.keys(result)
      self.pieChartData2.labels = keyList

      const valList = Object.values(result)
      self.pieChartData2.datasets[0].data = valList

      if (keyList.length > 5)
        self.addRandomColors(self.pieChartData2.datasets[0].backgroundColor, keyList.length)

      self.isDataDone2 = true;
    },
    generateRandomColor() {
      const randomColor = Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0');
      return '#' + randomColor.toUpperCase();
    },
    addRandomColors(colorList, numColors) {
      const self = this;
      for (let i = 0; i < numColors; i++) {
        colorList.push(self.generateRandomColor());
      }
    },
  }
}
</script>

<style scoped>

.box-container {
  width: 1110px;
  margin-left: auto;
  margin-right: auto;
}


.main-container {
  background: white;
  padding: 5px 38px;
}

.main-title {
  font-size: 24px;
  font-weight: 600;
  padding: 40px 0 30px 0;
}

.bs-flex {
  display: flex;
  flex-direction: row;
  max-width: 830px;
  justify-content: space-between;
}

.bs-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #333333;
  margin: 20px 0 15px;
}

.chart-container {
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 15px;
  padding-bottom: 20px;
}

.bs-sub-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #333333;
  padding: 20px 0 12px 20px;
}

/deep/ .chartjs-render-monitor {
  width: 400px !important;
}

.double-container {
  max-width: 830px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-bottom: 20px;
}

.double-chart-container .bs-sub-title {
  padding: 20px 0 12px 62px;
}

.null-box {
  width: 400px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.null-box img {
  width: 64px;
  height: 64px;
  margin-bottom: 5px;
}
</style>
